@tailwind base;

/* override base layer to not break existing images */
img {
  display: inline;
}
body {
  font-size: 1rem;
}

@tailwind components;

@layer components {
  .container-x {
    @apply px-1 sm:px-4 md:px-6 xl:px-24;
  }

  .container-y {
    @apply py-4 xl:py-[38px];
  }

  .link {
    @apply hover:text-link;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .page-title {
    @apply font-bold text-xl sm:text-3xl;
  }

  .btn {
    @apply h-8 text-sm flex items-center justify-center px-4 rounded-full whitespace-nowrap transition duration-300;
  }
  .btn-new {
    @apply text-base flex items-center justify-center py-4 px-7 rounded-md whitespace-nowrap transition duration-300 font-bold leading-4;
  }
  .btn:disabled,
  .btn-new:disabled,
  .btn-cancel:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .btn-secondary {
    @apply border border-solid border-gray-d9 text-black text-opacity-80;
  }
  .btn-secondary:hover,
  .btn-secondary.btn-active {
    @apply border-accent text-accent;
  }

  .btn-secondary-outline {
    @apply inline-flex items-center text-secondary border border-secondary bg-none;
  }

  .btn-primary {
    @apply inline-flex items-center text-secondary border border-primary bg-primary;
  }
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active {
    @apply border-primary-darkest bg-primary-darker text-secondary-darker;
  }

  .btn-primary-outline {
    @apply inline-flex items-center text-primary border border-primary bg-none;
  }
  .btn-primary-outline:hover,
  .btn-primary-outline:focus,
  .btn-primary-outline:active {
    @apply border-primary-darkest text-primary-darker;
  }

  .btn-white {
    @apply text-gray-01 border border-white bg-white;
  }
  .btn-white:hover,
  .btn-white:focus,
  .btn-white:active {
    @apply text-accent border-accent;
  }

  .btn-accent {
    @apply text-white border border-accent bg-accent;
  }
  .btn-accent:hover {
    @apply border-accent-pale-darker bg-accent-pale;
  }

  .btn-danger {
    @apply bg-danger text-white;
  }
  .btn-danger:hover {
    @apply bg-danger-darker;
  }

  .btn-cancel {
    @apply border-b border-amber-ff text-sm text-amber-ff;
  }

  .btn-xs {
    @apply h-7 px-2 text-sm;
  }

  .btn-lg {
    @apply h-10 px-5 text-base;
  }

  .btn-xl {
    height: 54px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 50px;
  }

  .btn-xl:hover {
    color: #fff;
  }

  .form-buttons {
    @apply py-3 sm:px-8 flex flex-wrap gap-4;
  }

  .sticky-submit-panel {
    @apply sticky bottom-0 bg-amber-f8 py-2.5 px-3 border-t border-gray-bf w-full flex justify-center sm:justify-end items-center gap-x-8;
  }

  .h-rest {
    @apply h-[calc(100vh-68px)] md:h-[calc(100vh-197px)] lg:h-[calc(100vh-128px)];
  }

  .bg-shadow-gradient-bottom {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.4) 35%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .bg-shadow-gradient-top {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 60%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
}

@tailwind utilities;

@layer utilities {
  .font-graduate {
    font-family: 'Graduate', serif;
  }

  .bg-for-payment {
    background: url('/images/payment-bg.png') #012138 center top;
  }

  .w-fit-content {
    width: fit-content;
  }
}
