.container {
  right: 16px;
  bottom: 16px;
  z-index: 1001;
  position: fixed;
  transition: all 0.2s linear;
}

.closed-chat-box {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.opened-chat-box {
  width: 400px;
  height: 600px;
  border-radius: 8px;
  z-index: 1001;
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    right: 0;
    top: 0;
  }
}
