.App {
  .ant-checkbox-wrapper {
    margin-left: 19px;

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background: white;
        border: 1px solid #d9d9d9;

        &:after {
          display: none;
        }

        &:before {
          content: '';
          position: absolute;
          top: 3px;
          left: 3px;
          right: 3px;
          bottom: 3px;
          background: #cbb133;
        }
      }
    }
  }
}
