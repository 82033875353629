.auth-container {
  background-image: url('/images/login-bg.png');
  background-size: contain;
  @apply py-20;
  .auth-wrapper {
    @apply w-[95%] h-auto md:w-fit md:min-w-[480px]  bg-white m-auto py-14 px-11 rounded-md;
    .btn-facebook {
      background: #3b5998;
      color: #fff;
      border: 1px solid #3b5998;
      .facebook-icon {
        transform: scale(1.35);
        margin-right: 5px;
      }
    }
    .custom-register-input {
      @apply w-full px-5 py-4 rounded-lg border border-gray-d9 leading-4;
    }
    .form-section {
      @apply flex flex-col items-start w-full md:w-[790px];
      &.small {
        @apply md:w-auto;
      }
    }
    h1 {
      @apply text-2xl font-bold text-secondary text-start;
    }
    h3 {
      @apply text-gray-8a my-3 text-start;
    }
    .additional-links {
      @apply mt-20 text-center w-full;
      p {
        @apply text-gray-8a;
        span {
          @apply text-blue-21 underline font-bold cursor-pointer;
        }
      }
    }
  }
}
