@import './variables.scss';
@import './@next/var.scss';

.outline-input-field {
  margin-bottom: 0;
  padding: 8px 0;
  .ant-input-affix-wrapper {
    background-color: rgba(0, 0, 0, 0.05);
    .ant-input {
      background-color: transparent;
    }
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: $baseColor;
    }
    &:focus,
    &.ant-input-affix-wrapper-focused {
      border-color: $baseColor;
      box-shadow: 0 0 0 2px $secondaryRGB;
    }
  }
  .ant-form-item-explain,
  .ant-form-item-explain-error {
    display: none;
  }
  .check-icon {
    color: $baseColor;
  }
}

.main-content {
  position: relative;
  width: 100%;
  height: 100vh;
  background: $baseColorWhite;
  box-shadow: $boxShadow;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 768px) {
    padding: 50px 0;
  }
  &__logo {
    max-width: 200px;
    width: 100%;
  }
}

.app-form-header {
  .mobile & {
    flex-wrap: wrap;
  }
  h1 {
    text-transform: capitalize;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    .mobile & {
      font-size: 1.3rem;
    }
    line-height: 36px;
    color: $baseColor;
    margin-bottom: 5px;
  }
}

.app-form {
  border: 1px solid $appBorderColor;
  border-radius: 4px;
  margin-top: 1.75rem;
  .mobile & {
    padding: 0 0.75rem 0 0.75rem;
  }
  .app-form-item {
    margin: 0;
    padding: 0.78rem 0 0.78rem 2rem;
    border-bottom: 1px solid $appBorderColor;
    .mobile & {
      padding: 1rem 0 1rem 0rem;
    }
    &[data-order]::before {
      content: attr(data-order) !important;
      background-color: $fillAppFormColor;
      margin-right: 0.5rem;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 18px;
      line-height: 16px;
      font-weight: bold;
      .mobile & {
        display: none;
      }
    }
  }

  .ant-col {
    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      color: $baseColor;
    }
  }

  .ant-row {
    align-items: flex-start;
  }
}

.app-form__message-label {
  border-bottom: none !important;
  font-size: 18px;
}

.app-form__message {
  margin: 0;
  padding: 0 65px 1rem;
  border-bottom: 1px solid $appBorderColor;

  textarea {
    border: none;
    border-radius: 20px;
    padding: 10px 1rem;
    background-color: #ffffff;
    &:focus,
    &:active,
    &:hover {
      background-color: #ffffff;
      border: 1px solid $appBorderColor !important;
    }
  }
}

.custom-wrapper {
  display: flex;
  margin: 10px 0;
  &__custom-btn {
    border-radius: 25px;
    background: $baseColor;
    width: 135px;
    height: 40px;
    margin: 0;
    font-size: 16px;
    border: none;
    &:hover {
      background: #013357;
    }
    &:focus {
      box-shadow: none;
      background: $baseColor;
      outline: none;
      border: none;
    }
  }
}

@mixin section-wrap {
  margin-top: 15px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  vertical-align: middle;
  flex: 1;
  margin: 0 auto;
  &__sub-child {
    flex: 1;
    overflow: auto;
    padding: 0px 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    &--main-content {
      width: 420px;
      @media (min-width: 320px) and (max-width: 560px) {
        width: 100%;
        max-width: unset;
      }
    }
    @media (min-width: 320px) and (max-width: 560px) {
      width: 100%;
      max-width: unset;
      padding: 15px;
    }
  }
}
.phone-wrapper {
  display: flex !important;
  width: 100%;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
  h5 {
    position: absolute;
    top: 51%;
    transform: translateY(-50%);
    left: 70px;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: red;
  }

  input {
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 4px 11px 4px 44px;
    &:focus-visible {
      outline: none;
    }
  }
  .ant-select-selection-item {
    bdo {
      display: none;
    }
  }
}
.ant-select-dropdown {
  width: 500px !important;
  .mobile & {
    width: 280px !important;
  }
}

.error {
  color: red;
  .click_link {
    color: $baseColor;
    margin-left: 3px;
    margin-right: 3px;
    text-decoration: underline;
  }
}

//////////////////////////////////////////////////////////
// Footer.scss
.main-footer {
  background: #012138;
  text-align: left;

  .footer-inner {
    padding: 0 92px;
    .mobile & {
      padding: 0 30px;
    }
  }

  .top-part {
    padding: 100px 0 54px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    .links-section {
      padding-top: 32px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 40%;
      .mobile & {
        width: 100%;
      }
    }

    .links {
      .title {
        color: white;
        font-weight: bold;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 15px;
      }

      a {
        display: block;
        color: white;
        opacity: 0.75;
        margin-bottom: 13px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .subscribe {
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.1);
      padding: 32px 48px;

      .mobile & {
        width: 100%;
        padding: 10px 12px;
      }
      .title {
        color: white;
        font-size: 16px;
        line-height: 28px;
      }

      .subscribe-form {
        margin: 15px 0 20px;
        height: 50px;
        width: 276px;
        max-width: 100%;
        position: relative;

        .subscribe-input {
          height: 100%;
          width: 100%;
          border-radius: 25px;
          padding: 0 70px 0 20px;
          font-size: 14px;
          line-height: 17px;
          color: #7a7e92;
        }

        .subscribe-submit {
          cursor: pointer;
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          background: #cbb133;
          color: white;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }

      .text {
        font-size: 12px;
        line-height: 20px;
        color: white;
        opacity: 0.6;
      }
    }
  }

  .bottom-part {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    display: flex;
    justify-content: center;
    height: 180px;
    .mobile & {
      height: auto;
      flex-direction: column;
      align-items: center;
    }

    .logo {
      display: block;
      width: 115px;
      position: absolute;
      top: 18px;
      left: 0;

      .mobile & {
        width: 100%;
        display: flex;
        justify-content: center;
        position: initial;
        top: initial;
        left: initial;
      }
      img {
        width: 100%;
        .mobile & {
          width: auto;
          height: 90px;
          padding: 6px;
        }
      }
    }

    .center-links {
      margin-top: 46px;
      .mobile & {
        margin: 30px 20px;
      }
      a {
        color: white;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        margin: 0 20px;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .right-links {
      position: absolute;
      top: 37px;
      right: 0;
      display: flex;
      align-items: center;
      .mobile & {
        margin: 0 30px 20px;
        position: initial;
        top: initial;
        right: initial;
      }

      a {
        margin-left: 15px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1.5px solid rgba(255, 255, 255, 0.25);
        color: white;
      }
    }
  }
}

// loader.scss
.loader {
  position: fixed !important;
  display: flex !important;
  height: 100%;
  width: auto;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  z-index: 99999 !important;
  .loader-text {
    text-align: center;
    img {
      width: 120px;
      height: auto;
    }
    p {
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 22px;
      margin: 0;
    }
  }
}

// logoComponents.scss
.main-content {
  background-image: linear-gradient(rgba(1, 33, 56, 0.8), rgba(1, 33, 56, 0.8)),
    url('/images/signup-page-bg.png') !important;
  background-size: cover !important;
  background-position: center !important;
  height: 100% !important;
  box-shadow: none !important;
  min-height: 80vh !important;
  .main-content__logo {
    transform: scale(1.5);
  }
}

// profileHeader.scss
// $baseColor: #16A3D4;
$break-mobile-small: 480px;
$break-mobile-medium: 567px;
$break-tablet-small: 767px;
$break-tablet-medium: 768px;

.ant-layout-content {
  background: #fff;
  padding: 0 10px;
  .ant-tabs-nav-wrap {
    @media screen and (max-width: $break-tablet-small) {
      padding: 0;
    }
    .ant-tabs-nav-scroll {
      margin-left: 50px;
      font-size: 17px;
      @media screen and (max-width: $break-mobile-small) {
        margin-left: 0;
        font-size: 10px;
      }
    }
  }
  .header {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    min-height: 50px;
    padding: 0 40px !important;
    @media screen and (max-width: 992px) {
      flex-direction: column-reverse;
      padding: 0 6px !important;
    }
    .ant-tabs-bar {
      margin: 0;
    }
    .btn-skip {
      margin-right: 20px;
      margin-top: 20px;
      border-radius: 19px;
      padding: 0 30px;
      border-color: #666666;
      color: #666666;

      @media screen and (max-width: $break-mobile-small) {
        margin-left: 0;
      }
    }
    .btn-skip-only {
      right: 4% !important;
    }
    .btn-save {
      margin-top: 20px;
      border-radius: 19px;
      padding: 0 30px;
      border-color: #666666;
      color: #fff;

      background-color: $baseColor !important;
      @media screen and (max-width: $break-mobile-small) {
        float: none;
      }
    }
    .btn-next,
    .btn-previous {
      margin-top: 20px;
      border-radius: 19px;
      padding: 0 30px;
      border-color: #666666;
      color: #fff;

      background-color: $baseColor !important;
      @media screen and (max-width: $break-mobile-small) {
        float: none;
      }
    }
    .btn-previous {
      margin-right: 20px;
    }

    @media screen and (max-width: $break-mobile-medium) {
      margin-left: 0px;
    }
  }
}
.ant-tabs-nav-scroll {
  .ant-tabs-nav {
    .ant-tabs-tab {
      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
      &::after {
        content: '\f105';
        font-family: FontAwesome;
        font-size: 30px;
        position: relative;
        left: 30px;
        top: 5px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}

.ant-tabs-tab-active {
  .ant-tabs-tab:after {
    content: '\f105';
    font-family: FontAwesome;
    font-size: 30px;
    position: relative;
    left: 30px;
    top: 5px;
    color: #a1a1a1;
  }
}

.ant-tabs-ink-bar {
  .ant-tabs-ink-bar-animated {
    display: none;
  }
}

// Done.scss
#signup-level-name {
  color: $primaryColor;
}

// SignUpFlow.scss
@mixin pending {
  border-color: $disabledGrey;
  color: $disabledGrey;
}

.signup-flow-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
  border-bottom: 1px solid #adadad;
  padding: 1rem 2rem;
  background-color: $backgroundLightBlue;
  .mobile & {
    gap: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
}

.signup-step-number {
  @include pending;

  min-width: max-content;
  font-weight: 800;
  font-size: 1rem;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  border-width: 2px;
  border-style: solid;

  &.current {
    background-color: $baseColor;
    color: $baseColorWhite;
    border-color: $baseColor;
  }
}

.signup-step-text {
  @include pending;

  font-size: 1rem;
  font-weight: 800;

  &.complete,
  &.current {
    color: $baseColor;
  }
}

.signup-step-item {
  display: flex;
  gap: 0.75rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tooltip {
  max-width: 30em;
  width: max-content;
  background-color: #fff;
  font-size: 0.825em;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  transition: all 3s linear;
  @apply absolute z-10 p-3 bottom-[calc(100%+10px)] rounded-lg;
}
.tooltip::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 10px;
  border: 0.5rem solid transparent;
  border-bottom: none;
  border-top-color: #fff;
}

.tooltip.tooltip-right {
  right: -10px;
}

.tooltip.tooltip-right::before {
  right: 10px;
}

.tooltip.tooltip-left {
  left: -10px;
}

.tooltip.tooltip-left::before {
  left: 10px;
}

// Form.scss
.signUp-wrapper {
  @include section-wrap();
  height: auto;
  padding-top: 65px;
  padding-bottom: 90px;

  h1 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 35px;
  }

  .header-text {
    text-align: left;
  }

  .custom-wrapper {
    width: 100%;

    .custom-wrapper__custom-btn {
      width: 100%;
    }
  }

  .btn-facebook {
    width: 100%;

    .facebook {
      background: #3b5998;
      color: #fff;
      font-size: 16px;
      border-radius: 25px;
      border: 1px solid #3b5998;
      width: 100%;
      height: 40px;
      .facebook-icon {
        transform: scale(1.35);
        margin-right: 5px;
      }
    }
  }

  .outline-input-field {
    padding: 5px 0;
  }

  .outline-input-field .ant-input-affix-wrapper {
    background: white;
    border-radius: 20px;
    border: 1px solid #f0f4f8;
  }

  p {
    font-size: 14px;
    font-weight: 500;

    span {
      color: #4e47ff;
    }
  }

  .log-page {
    margin-top: 25px;

    p {
      color: #979797;
      font-size: 14px;
      margin: 0px;
      text-align: left;

      span {
        color: #4178a8;
        font-weight: 600;
      }

      span:hover {
        cursor: pointer;
      }
    }
  }

  .input-label {
    text-align: left;
  }

  .ant-card-bordered {
    border-radius: 20px;
  }

  .ant-card-body {
    padding: 10px 20px;
    padding-right: 30px;
    border-radius: 20px;
  }

  .psw-hint-card {
    display: flex;

    p {
      margin-bottom: 0px;
    }

    i {
      margin-right: 6px;
      margin-top: 3px;
    }
  }

  .ant-form-item-control-input-content {
    text-align: left;
  }

  .error-div .ant-input-affix-wrapper {
    background-color: white;
  }
}

.password-help-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  &__content {
    flex: 1;

    &__list {
      display: flex;
      align-items: center;
      line-height: 25px;

      .identify {
        height: 10px;
        width: 10px;
        border-radius: 25px;
        background: #e8e8e8;
        margin-right: 10px;
      }

      span {
        color: #a1a1a1;
      }

      .validation-success {
        color: #333;
      }

      .identify-passed {
        background: $baseColor;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .signUp-wrapper {
    display: block;
    width: 100%;

    margin: 0 auto;

    &__sub-wrap {
      padding: 15px;
    }

    .log-page {
      p {
        font-size: 13px;
        text-align: center;
      }
    }

    .btn-register {
      text-align: center;
    }
  }

  .btn-facebook {
    display: flex;
    justify-content: center;
  }

  .password-help-wrapper {
    &__content {
      flex: none !important;
      margin-right: 15px;
    }
  }
}

// Layout.scss
.setup-account-layout {
  .mobile & {
    padding: 1em;
  }
  background: url('/images/payment-bg.png') #012138 center top;
  text-align: center;
  display: flex;
  margin: 0;
  bottom: 0;
  flex-flow: column;
  padding-top: 1rem;
  img {
    .mobile & {
      width: 100%;
    }
  }
}

// LetsStart.scss
.text-container {
  margin-bottom: 3rem;

  * {
    color: white;
  }

  h1 {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 36px;
  }

  h4 {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 22px;
  }
}

.lower-section {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

.btn-xl {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

// MembershipPlanCard.scss
.MembershipPlanCard {
  background: linear-gradient(180deg, #cbb133 0%, rgba(203, 177, 51, 0) 100%);
  mix-blend-mode: normal;

  .card__btn-container {
    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(transparent, #cbb133 80%) 1;
    border-top: 0;
  }
}

.text-shadow {
  text-shadow: 0px 1px rgb(203 177 51);
  color: white;
}

// signUpChoices.scss
.cards-wrapper {
  margin-left: 40px;
  display: flex;
  flex-wrap: wrap;
  .custom-col {
    display: flex;
    flex-wrap: wrap;
    //width: 15%;
    padding: 15px;
    .overlay {
      position: relative;
      .icon-span {
        background: rgba(0, 0, 0, 0.5);
        opacity: 1;
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        i {
          color: #fff;
          font-size: 50px;
          position: absolute;
          top: 27%;
          right: 71px;
          margin: 0 auto;
        }
        img {
          color: #fff;
          font-size: 50px;
          position: absolute;
          margin: 0 auto;
          height: 25px;
          width: 30px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .ant-collapse {
    width: 97%;
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 19px 0 12px 40px;
        font-size: 18px;
      }

      .ant-collapse-content {
        min-height: 50px;
        max-height: 500px;
        overflow-y: scroll;
        //-ms-overflow-style: none;  // IE 10+
        //overflow: -moz-scrollbars-none;  // Firefox
        .lazy-loader {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
        .no-data-div {
          text-align: center;
        }
      }
      .ant-collapse-content::-webkit-scrollbar {
        width: 1em;
        border-radius: 25px;
      }
      .ant-collapse-content::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 25px;
      }
      .ant-collapse-content::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        border-radius: 25px;
      }
    }
  }
}
.individual-card {
  width: 100%;
  height: auto;
  .ant-card-body {
    text-align: center;
  }
  img {
    height: auto;
    width: 100%;
    display: block;
  }
}

// userHeader.scss
$baseColor: #16a3d4;

.ant-layout-header {
  background: $baseColor;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  img {
    margin-left: 50px;
  }
}

.ant-dropdown-trigger {
  margin: 0 60px;
  color: #fff;
  font-weight: 600;
  img {
    height: 50px;
    width: auto;
  }
}
.ant-dropdown-trigger:hover {
  cursor: pointer;
}

@media (min-width: 320px) and (max-width: 768px) {
  .ant-layout-header {
    display: flex;
    justify-content: space-around;
    padding: 3px;
    img {
      margin-left: 0;
    }
  }
  .ant-dropdown-trigger {
    margin: 0;
  }
}

// AdminLoginPage.scss
