@import '~antd/dist/antd.css';
@import './styles/checkbox';
@import './tailwind';
@import './styles/auth';

/* latin */
@font-face {
  font-family: 'Graduate';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Graduate-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #011e2e #14445d;
}
*::-webkit-scrollbar {
  width: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: #011e2e;
}
*::-webkit-scrollbar-track {
  background: #14445d;
}
html,
body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.button[disabled] {
  cursor: not-allowed;
}

.App {
  text-align: center;
  position: relative;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.custom-scroll::-webkit-scrollbar {
  width: 1em;
  border-radius: 25px;
}

.custom-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 25px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: inherit;
}

input:focus,
textarea:focus {
  outline: 0;
}

html > body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.container {
  padding: 6rem;
  .mobile & {
    padding: 1rem;
  }
}

.container .title {
  font-family: Lato;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #012138;
}

.flex-space-between,
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-start,
.flex-s {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

// theme overrides
html > body {
  .ant-typography {
    color: #012138;
  }

  .ant-btn-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tree-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .site-tree-search-value {
    color: #f50;
  }

  .ant-btn {
    &:hover,
    &:focus,
    &:active {
      color: #4293ac;
    }
  }

  .ant-btn-dangerous {
    &:hover,
    &:focus,
    &:active {
      color: #a3271e;
      border-color: #a3271e;
    }
  }

  .ant-btn-primary {
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      border-color: #857218;
      background-color: #b9a644;
    }
  }

  .ant-btn-primary {
    border-color: #cbb135;
    background-color: #cbb135;

    &:hover,
    &:focus,
    &:active {
      border-color: #857218;
      background-color: #b9a644;
    }
    &.dark-text-btn {
      color: #012138;
    }
    &.light-blue-btn {
      background-color: #00c2ff;
      border-color: #00c2ff;
      color: #fff;

      &:hover,
      &:focus,
      &:active {
        border-color: #00c2ff;
        border-color: #4293ac;
        background-color: #4db2d1;
      }
    }
  }
}

.checkbox-container {
  .ant-checkbox-wrapper {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #cbb133 !important;
      border: none;

      &::before {
        content: none;
      }
    }

    .ant-checkbox-inner {
      &:hover {
        border-color: #d9d9d9;
      }

      &::before {
        content: '';
        background-color: #d9d9d9;
        width: 8px;
        height: 8px;
        left: 3px;
        top: 3px;
        position: absolute;
      }
    }

    .ant-checkbox:hover::after,
    .ant-checkbox-wrapper:hover .ant-checkbox::after {
      visibility: hidden;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #d9d9d9;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #d9d9d9;
    }
  }
}

.ant-popover-buttons {
  display: flex;
}

.ant-picker-datetime-panel {
  flex-direction: row;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

pre {
  background-color: #003049;
  color: #ccc;
  padding: 10px;
  margin-top: 5px;
}

#stream-preview {
  background-color: #011e2e;
}
.showcase-item-card {
  @apply shadow-lg bg-white rounded-lg;
  width: 270px;
  height: 470px;
  position: relative;

  &:hover .view-link {
    opacity: 1;
    height: 39px;
  }
  img {
    width: 100%;
    height: 330px;
    object-fit: cover;
  }
  .info {
    height: 140px;
  }
  .types {
    .button {
      font-size: 14px;
      color: #8a939b;
    }
    .separator {
      font-size: 14px;
      color: #8a939b;
      margin: 0 2px;
    }
  }

  .view-link {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    font-weight: bold;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  font-weight: bolder;
}

div[data-radix-popper-content-wrapper] {
  z-index: 1100 !important;
}

.notMirrored {
  .agora_video_player {
    transform: initial !important;
  }
}
.video-js .vjs-control-bar {
  @media screen and (max-width: 768px) {
    bottom: 60px !important;
  }
}

.agora-viewer-playback {
  .agora_video_player {
    object-fit: contain !important;
  }
}
