$baseColor: #003049;
$baseRGB: rgba(1, 33, 56, 0.2);

$secondaryColor: #f7eedc;
$secondaryRGB: rgba(247, 238, 220, 0.2);

$baseColorWhite: #f5f5f5;
$boxShadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

// used on search page for background stripes
$showcaseItemCardHeight: 317px;

$fillAppFormColor: #cbb133;
$appBorderColor: #cbd1d8;

$successGreen: #33b857;

$disabledGrey: #cfcfcf;
$backgroundGrey: #f9f9f9;
$backgroundLightBlue: #f0f4f8;

$primaryColor: #e0c321;
$textColor: #003049;
